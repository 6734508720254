








import { MetaInfo } from 'vue-meta'
import { Vue, Component } from 'vue-property-decorator'

import SupportTable from '@/components/tables/SupportTable.vue'

@Component({
  components: { SupportTable },
})
export default class SupportList extends Vue {
  private metaInfo (): MetaInfo {
    return {
      title: 'Техподдержка',
    }
  }
}
